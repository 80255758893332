var pickupLocationData = [
  {
    "": 0,
    "main_locations": "Zwönitzer Straße 27 09481 Elterlein",
    "shop_url": "www.pyroweb.de",
    "shop_name": "pyroweb GmbH",
    "shop_further_locations": "",
    "main_coordinates": "[50.582888600000004, 12.86198136421371]",
    "shop_further_location_coordinates": []
  },
  {
    "": 1,
    "main_locations": "Zum Kleinen Loh 1 27383 Westervesede",
    "shop_url": "www.pyroland.de",
    "shop_name": "Pyroland",
    "shop_further_locations": "",
    "main_coordinates": "[53.124790, 9.541200]",
    "shop_further_location_coordinates": []
  },
  {
    "": 2,
    "main_locations": "Am Roßberg 3, 96132 Schlüsselfeld",
    "shop_url": "www.shop.roeder-feuerwerk.de",
    "shop_name": "Röder Feuerwerk",
    "shop_further_locations": "16727 Berlin Velten\n13127 Berlin Nord\n21227 Hamburg Süd\n76707 Hambrücken",
    "main_coordinates": "[49.7478555, 10.64287282522645]",
    "shop_further_location_coordinates": [[52.6705209, 13.212890401120418], [46.3785823, -98.4898183], [53.5251691, 9.9918317], [49.1861055, 8.5449438]]
  },
  {
    "": 3,
    "main_locations": "Baiertaler Straße 98 69168 Wiesloch",
    "shop_url": "www.feuerwerk.com",
    "shop_name": "Beisel Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[49.2989396, 8.7203389]",
    "shop_further_location_coordinates": []
  },
  {
    "": 4,
    "main_locations": "Max-Koska-Str. 4 13189 Berlin",
    "shop_url": "www.feuerwerksvitrine.de",
    "shop_name": "Feuerwerksvitrine",
    "shop_further_locations": "",
    "main_coordinates": "[52.5531452, 13.4260994]",
    "shop_further_location_coordinates": []
  },
  {
    "": 5,
    "main_locations": "Mühlenstr. 8a 14167 Berlin",
    "shop_url": "www.feuerwerkshop.de",
    "shop_name": "Feuerwerkshop",
    "shop_further_locations": "13187 Berlin Pankow\n12247 Berlin Lankwitz\n13585 Berlin Spandau \n12049 Berlin Neukölln \n20095 Hamburg \n41061 Mönchenglattbach\n36456 Barchfeld \n04103 Leipzig\n90402 Nürnberg\n78224 Singen",
    "main_coordinates": "[52.4299717, 13.2618285]",
    "shop_further_location_coordinates": [[52.5660169, 13.4030903], [52.4336977, 13.3454858], [52.5347694, 13.1956287], [52.4811497, 13.4353501], [53.550341, 10.000654],[50.8002153, 10.2997036], [51.3406321, 12.3747329], [49.453872, 11.077298], [47.7617515, 8.8348709]]
  },
  {
    "": 6,
    "main_locations": "Bürgermeister-Zoch-Straße 19 92364 Deining",
    "shop_url": "www.pyrographics-feuerwerkshop.de",
    "shop_name": "Pyrographics",
    "shop_further_locations": "92331 Parsberg\n",
    "main_coordinates": "[49.2324088, 11.5500802]",
    "shop_further_location_coordinates": [[49.161300, 11.718080]]
  },
  {
    "": 7,
    "main_locations": "Chemnitzer Straße 12 09579 Grünhainichen",
    "shop_url": "www.feuerwerkland-shop.de",
    "shop_name": "Feuerwerkland",
    "shop_further_locations": "",
    "main_coordinates": "[50.769772399999994, 13.160731849999998]",
    "shop_further_location_coordinates": []
  },
  {
    "": 8,
    "main_locations": "Wilhelmstraße 16 47495 Rheinberg",
    "shop_url": "www.xpyro.de",
    "shop_name": "xPyro",
    "shop_further_locations": "47506 Neukirchen-Vluyn\n26655 Westerstede",
    "main_coordinates": "[51.596774249999996, 6.576258646482689]",
    "shop_further_location_coordinates": [[51.4380842, 6.5303644], [53.2575197, 7.9273367]]
  },
  {
    "": 9,
    "main_locations": "Holtkämpe 12 48734 Reken",
    "shop_url": "www.colibri-feuerwerk.de",
    "shop_name": "Colibri Feuerwerk",
    "shop_further_locations": "Eisenstraße 42 44145 Dortmund",
    "main_coordinates": "[51.8034014, 7.0987494]",
    "shop_further_location_coordinates": [[51.535593, 7.468903]]
  },
  {
    "": 10,
    "main_locations": "Barnimstr. 30 15569 Woltersdorf",
    "shop_url": "www.pyrolager.de",
    "shop_name": "Pyrolager",
    "shop_further_locations": "10115 Berlin",
    "main_coordinates": "[52.4560972, 13.7406781]",
    "shop_further_location_coordinates": [[52.5170365, 13.3888599]]
  },
  {
    "": 11,
    "main_locations": "Südweg 50 26135 Oldenburg",
    "shop_url": "www.pyrostation.de",
    "shop_name": "Pyrostation",
    "shop_further_locations": "",
    "main_coordinates": "[53.1168307, 8.254808149386502]",
    "shop_further_location_coordinates": []
  },
  {
    "": 12,
    "main_locations": "Holtkämpe 12 48734 Reken",
    "shop_url": "www.pyrodimension.de",
    "shop_name": "Pyrodimension",
    "shop_further_locations": "",
    "main_coordinates": "[51.8034014, 7.0987494]",
    "shop_further_location_coordinates": []
  },
  {
    "": 13,
    "main_locations": "Vor den Höfen 7 38551 Ribbesbüttel",
    "shop_url": "www.pyrostern.de",
    "shop_name": "Pyrostern",
    "shop_further_locations": "Hauptstraße 25 38550 Isenbüttel",
    "main_coordinates": "[52.42466835, 10.48884]",
    "shop_further_location_coordinates": [[52.435561199999995, 10.571786187152629]]
  },
  {
    "": 14,
    "main_locations": "Im Bardel 1 49832 Freren",
    "shop_url": "www.toschpyro.com",
    "shop_name": "Toschpyro",
    "shop_further_locations": "Ringstraße 9 49134 Wallenhorst\n49074 Osnabrück\n26603 Aurich\n10115 Berlin\n97421 Schweintfurt\n95511 Mistelbach",
    "main_coordinates": "[52.4229607, 7.5588419]",
    "shop_further_location_coordinates": [[52.3541883, 8.0044526], [52.2719595, 8.047635], [53.4695604, 7.4823808], [52.5170365, 13.3888599], [49.9141674, 11.5142216]]
  },
  {
    "": 15,
    "main_locations": "Fürstenwalder Weg 11 C 15711 Königs Wusterhausen",
    "shop_url": "www.pulver-pyrotechnik.de",
    "shop_name": "Pulver Pyrotechnik",
    "shop_further_locations": "10115 Berlin",
    "main_coordinates": "[52.302285850000004, 13.661230122351888]",
    "shop_further_location_coordinates": [[52.5170365, 13.3888599]]
  },
  {
    "": 16,
    "main_locations": "Stendaler Str.48 39638 Gardelegen",
    "shop_url": "www.pyrodragon-feuerwerk.de",
    "shop_name": "Pyrodragon",
    "shop_further_locations": "",
    "main_coordinates": "[52.5285558, 11.4042268]",
    "shop_further_location_coordinates": []
  },
  {
    "": 17,
    "main_locations": "Nordstraße 44 41515 Grevenbroich",
    "shop_url": "www.pyroteam-store.de",
    "shop_name": "Pyroteam Store",
    "shop_further_locations": "",
    "main_coordinates": "[51.089793, 6.6041286]",
    "shop_further_location_coordinates": []
  },
  {
    "": 18,
    "main_locations": "Dittrichring 17 04109 Leipzig",
    "shop_url": "www.feuerwerksverkauf.de",
    "shop_name": "Feuerwerksverkauf",
    "shop_further_locations": "Str. des 17. Juni 4 04425 Taucha\nZaschendorfer Straße. 77a 01662 Meißen",
    "main_coordinates": "[51.3407566, 12.3705033]",
    "shop_further_location_coordinates": [[51.3744236, 12.475225678584575], [51.1498341, 13.5182404]]
  },
  {
    "": 19,
    "main_locations": "Hamburger Weg 17 – 21 27751 Delmenhorst",
    "shop_url": "www.feuerwerkswelt.de",
    "shop_name": "Feuerwerkswelt",
    "shop_further_locations": "",
    "main_coordinates": "[53.0525054, 8.6549431]",
    "shop_further_location_coordinates": []
  },
  {
    "": 20,
    "main_locations": "Im Steinbruch 1 37696 Marienmünster",
    "shop_url": "www.shop.aba-pyrotechnik.de",
    "shop_name": "ABA",
    "shop_further_locations": "23569 Lübeck Zum Winderhitzer 2",
    "main_coordinates": "[51.78988895, 9.227387208993687]",
    "shop_further_location_coordinates": [[53.90212615, 10.809744887639283]]
  },
  {
    "": 21,
    "main_locations": "Ummerstrasse 85 41748 Viersen",
    "shop_url": "www.we-love-pyro.de",
    "shop_name": "We Love Pyro",
    "shop_further_locations": "Deller Weg 109 41334 Nettetal",
    "main_coordinates": "[51.2394087, 6.4190033]",
    "shop_further_location_coordinates": [[51.3373399, 6.1969392]]
  },
  {
    "": 22,
    "main_locations": "Urenfleet 44 21129 Hamburg",
    "shop_url": "www.sstotz.de",
    "shop_name": "Sstotz",
    "shop_further_locations": "",
    "main_coordinates": "[53.52163765, 9.7840974]",
    "shop_further_location_coordinates": []
  },
  {
    "": 23,
    "main_locations": "Bogestrasse 54-56 53783 Eitorf",
    "shop_url": "www.weco-feuerwerk.shop",
    "shop_name": "WECO",
    "shop_further_locations": "Wecostrasse 6 53783 Eitorf\nUhlenhorster Weg 55 24159 Kiel",
    "main_coordinates": "[50.76862165, 7.467864296367015]",
    "shop_further_location_coordinates": [[50.76656385, 7.473696207403359], [54.404239, 10.1558812]]
  },
  {
    "": 24,
    "main_locations": "Rigistraße 8 12277 Berlin",
    "shop_url": "www.nico-europe.com",
    "shop_name": "NICO",
    "shop_further_locations": "Flügel 1 42369 Wuppertal",
    "main_coordinates": "[52.4118563, 13.3800471]",
    "shop_further_location_coordinates": []
  },
  {
    "": 25,
    "main_locations": "Werkstr. 10 78176 Blumberg",
    "shop_url": "www.profipyro.de",
    "shop_name": "Profi Pyro",
    "shop_further_locations": "",
    "main_coordinates": "[47.836199199999996, 8.557617302823306]",
    "shop_further_location_coordinates": []
  },
  {
    "": 26,
    "main_locations": "An der Laugna 1 86494 Emersacker",
    "shop_url": "www.raketenmodellbau-klima.de",
    "shop_name": "Raketenmodellbau Klima",
    "shop_further_locations": "",
    "main_coordinates": "[48.4954874, 10.6752777]",
    "shop_further_location_coordinates": []
  },
  {
    "": 27,
    "main_locations": "Meschwitzstraße 9 01099 Dresden",
    "shop_url": "www.feuerwerk-outlet.de",
    "shop_name": "Feuerwerk Outlet",
    "shop_further_locations": "01097 Dresden\n01705 Freital\n01445 Coswig\n08451 Crimmizschau\n10115 Berlin\n03222 Lübbenau\n03099 Gulben\nBerliner Strasse 74 60311 Frankfurt\nSiedlung am Wasserturm 13 06268 Obhausen\n18055 Rostock\n06122 Halle\n06268 Obhausen\nLerchenwuhne 125 39128 Magdeburg",
    "main_coordinates": "[51.0843488, 13.7658869]",
    "shop_further_location_coordinates": [[51.0493286, 13.7381437], [51.016265, 13.6530329], [51.1267305, 13.5783983], [52.5170365, 13.3888599], [51.8680114, 13.9689477], [51.78147935, 14.255842984293988], [50.1106386, 8.6776218], [51.3914638, 11.6588089], [54.0924445, 12.1286127], [51.4825041, 11.9705452], [51.3916284, 11.6508014], [52.1623543, 11.6016955]]
  },
  {
    "": 28,
    "main_locations": "Hans-Holbein-Str. 25 15827 Blankenfelde",
    "shop_url": "www.pyro-in-germany.de",
    "shop_name": "Pyro in Germany",
    "shop_further_locations": "",
    "main_coordinates": "[52.3269651, 13.398706140437834]",
    "shop_further_location_coordinates": []
  },
  {
    "": 29,
    "main_locations": "Emscherallee 11 44369 Dortmund",
    "shop_url": "www.raketenzauber.de",
    "shop_name": "Feuerbändiger",
    "shop_further_locations": "Saarstrasse 7 45663 Recklinghausen",
    "main_coordinates": "[51.5406661, 7.4124721]",
    "shop_further_location_coordinates": [[51.56921025, 7.2201541291298135]]
  },
  {
    "": 30,
    "main_locations": "Laaberallee 2 93099 Mötzing",
    "shop_url": "www.pyrotechnik.net",
    "shop_name": "Pyroflash",
    "shop_further_locations": "",
    "main_coordinates": "[48.8929878, 12.3749122]",
    "shop_further_location_coordinates": []
  },
  {
    "": 31,
    "main_locations": "Lohhofstraße 99 32108 Bad Salzuflen",
    "shop_url": "www.shop.pyrondo.de",
    "shop_name": "Pyrondo",
    "shop_further_locations": "Krumme Weide 32a 32108 Bad Salzuflen",
    "main_coordinates": "[52.071551400000004, 8.771132125322818]",
    "shop_further_location_coordinates": [[52.0703112, 8.7533569]]
  },
  {
    "": 32,
    "main_locations": "Anton-Sommer-Straße 13 07407 Rudolstadt",
    "shop_url": "www.pyrospecky.com",
    "shop_name": "Pyro Specky",
    "shop_further_locations": "",
    "main_coordinates": "[50.7192559, 11.3428813]",
    "shop_further_location_coordinates": []
  },
  {
    "": 33,
    "main_locations": "Überseering 22 27580 Bremerhaven",
    "shop_url": "www.comet-partyshop.de",
    "shop_name": "Comet",
    "shop_further_locations": "",
    "main_coordinates": "[53.596265900000006, 8.550756551831313]",
    "shop_further_location_coordinates": []
  },
  {
    "": 34,
    "main_locations": "Fuststr. 46 12459 Berlin",
    "shop_url": "www.pyro-shop24.de",
    "shop_name": "Pyro Shop 24",
    "shop_further_locations": "",
    "main_coordinates": "[52.468174, 13.5128311]",
    "shop_further_location_coordinates": []
  },
  {
    "": 35,
    "main_locations": "Käthe-Kollwitz-Str. 18 15711 Königs Wusterhausen",
    "shop_url": "www.feuerwerktraum.de",
    "shop_name": "Feuerwerktraum",
    "shop_further_locations": "",
    "main_coordinates": "[52.2868658, 13.6166849]",
    "shop_further_location_coordinates": []
  },
  {
    "": 36,
    "main_locations": "Kaufbacher Ring 2 01723 Kesselsdorf",
    "shop_url": "www.krause-sohn.de",
    "shop_name": "Krause & Sohn",
    "shop_further_locations": "",
    "main_coordinates": "[51.0358648, 13.5837329]",
    "shop_further_location_coordinates": []
  },
  {
    "": 37,
    "main_locations": "Gleichmannstr. 35 95326 Kulmbach",
    "shop_url": "www.firework-shop.de",
    "shop_name": "Event Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[50.1206867, 11.443099010615807]",
    "shop_further_location_coordinates": []
  },
  {
    "": 38,
    "main_locations": "Lübbener Str. 10 15910 Unterspreewald Ot Neu Lübbenau",
    "shop_url": "www.feuerwerk-spreewald.de",
    "shop_name": "Feuerwerk Spreewald",
    "shop_further_locations": "",
    "main_coordinates": "[52.059370, 13.895870]",
    "shop_further_location_coordinates": []
  },
  {
    "": 39,
    "main_locations": "Kröberner Str. 1 04617 Rositz",
    "shop_url": "www.pyroshop-dreamfireworks.de",
    "shop_name": "Dream Fireworks",
    "shop_further_locations": "",
    "main_coordinates": "[51.0149251, 12.3649249]",
    "shop_further_location_coordinates": []
  },
  {
    "": 40,
    "main_locations": "Sternstraße 5 06108 Halle",
    "shop_url": "www.kagofeuerwerk.de",
    "shop_name": "Kagofeuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[51.48037295, 11.972554043102292]",
    "shop_further_location_coordinates": []
  },
  {
    "": 41,
    "main_locations": "Basedowsweg  3 21522 Hohnstorf",
    "shop_url": "www.elbfeuerwerk.de",
    "shop_name": "Elbfeuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[53.364954, 10.555706795346591]",
    "shop_further_location_coordinates": []
  },
  {
    "": 42,
    "main_locations": "Wittelsbacherstr. 21 12309 Berlin",
    "shop_url": "www.lichtenrader-feuerwerkverkauf.de",
    "shop_name": "Lichtenrader Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[52.38417795, 13.416883714337748]",
    "shop_further_location_coordinates": []
  },
  {
    "": 43,
    "main_locations": "Dörkesdohr 1 42349 Wuppertal",
    "shop_url": "www.talfeuerwerk.de",
    "shop_name": "Talfeuerwerk",
    "shop_further_locations": "Kellerstr. 25a 58456 Witten",
    "main_coordinates": "[51.2124206, 7.149634763237083]",
    "shop_further_location_coordinates": [[51.394121,7.296408]]
  },
  {
    "": 44,
    "main_locations": "Zum Winderhitzer 2 23569 Lübeck",
    "shop_url": "www.nord-feuerwerk.de",
    "shop_name": "Feuerwerk Depot Nord",
    "shop_further_locations": "24103 Kiel\n21614 Buxtehude\n18055 Rostock\n33039 Nieheim\n37170 Uslar\n48653 Coesfeld\n34286 Spangenberg\n45739 Oer-Erkenschwick\n16727 Berlin-Velten",
    "main_coordinates": "[53.90212615, 10.809744887639283]",
    "shop_further_location_coordinates": [[54.3227085, 10.135555], [53.4767351, 9.7003941], [54.0924445, 12.1286127], [51.8039402, 9.1112266], [51.658901, 9.6347785], [51.9458943, 7.1691108], [51.1265161, 9.7013806], [51.6398512, 7.2605914], [52.6705209, 13.212890401120418]]
  },
  {
    "": 45,
    "main_locations": "Wunstorfer Landstraße 54 30453 Hannover",
    "shop_url": "www.mein.feuerwerkhannover.de",
    "shop_name": "Feuerwerk Hannover",
    "shop_further_locations": "",
    "main_coordinates": "[52.3847649, 9.663309]",
    "shop_further_location_coordinates": []
  },
  {
    "": 46,
    "main_locations": "Daudetstraße 2a 81245 München",
    "shop_url": "www.goetterfunken-feuerwerke.de",
    "shop_name": "Götterfunken Feuerwerke",
    "shop_further_locations": "Landwehrstr. 79 80336 München",
    "main_coordinates": "[48.1637526, 11.4454687]",
    "shop_further_location_coordinates": [[48.1363181, 11.5542074]]
  },
  {
    "": 47,
    "main_locations": "Am Wieselbach 15 45257 Essen",
    "shop_url": "www.ruhrfeuerwerk-shop.de",
    "shop_name": "Ruhr Feuerwerk",
    "shop_further_locations": "Ruhrölstraße 1 46240 Bottrop",
    "main_coordinates": "[51.38411845, 7.072780169196158]",
    "shop_further_location_coordinates": [[51.5329418, 6.982074435288023]]
  },
  {
    "": 48,
    "main_locations": "Hallstedt 9a 27211 Bassum",
    "shop_url": "www.knallkoeppe.com",
    "shop_name": "Knallköppe",
    "shop_further_locations": "Buchenweg 6a 32609 Hüllhorst\nDaimlerstraße 22 25337 Elmshorn\nSeggerner Str.2 26655 Westerstede",
    "main_coordinates": "[52.8299525, 8.8157842]",
    "shop_further_location_coordinates": [[52.28176605, 8.635263791977282], [53.74277445, 9.689279695824293], [53.2448996, 7.8931503]]
  },
  {
    "": 49,
    "main_locations": "Am Bahnhof 12 26655 Westerstede",
    "shop_url": "www.shop1.ahlers-pyrotechnik.de",
    "shop_name": "Ahlers Pyrotechnik",
    "shop_further_locations": "Buchenweg 6a 32609 Hüllhorst\nHartfeldstraße 42 47506 Neukirchen-Vluyn",
    "main_coordinates": "[53.2540209, 7.9312713]",
    "shop_further_location_coordinates": [[52.28176605, 8.635263791977282], [51.436868450000006, 6.546864149999999]]
  },
  {
    "": 50,
    "main_locations": "Kupferstraße 1 53842 Troisdorf",
    "shop_url": "www.pyrostore24.de",
    "shop_name": "Pyrotec Cologne",
    "shop_further_locations": "",
    "main_coordinates": "[50.8238409, 7.115382650000001]",
    "shop_further_location_coordinates": []
  },
  {
    "": 51,
    "main_locations": "Waldweg 15 25779 Wiemerstedt",
    "shop_url": "www.neumann-feuerwerk.de",
    "shop_name": "Neumann Feuerwerk",
    "shop_further_locations": "Meldorfer Straße 141 25746 Heide",
    "main_coordinates": "[54.2624636, 9.1463655]",
    "shop_further_location_coordinates": [[54.18492655, 9.090480752261048]]
  },
  {
    "": 52,
    "main_locations": "Schlotthauerstr. 14a 81541 München",
    "shop_url": "www.shop.pyrowuerfel.de",
    "shop_name": "Pyrowürfel",
    "shop_further_locations": "Josef-Frankl-Straße 4 80995 München",
    "main_coordinates": "[48.1230232, 11.5775138]",
    "shop_further_location_coordinates": [[48.21408915, 11.540055209351275]]
  },
  {
    "": 53,
    "main_locations": "Deenborn, 1 25704 Bargenstedt",
    "shop_url": "www.mfg-feuerwerk.de",
    "shop_name": "MFG Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[54.09634585, 9.145750166825096]",
    "shop_further_location_coordinates": []
  },
  {
    "": 54,
    "main_locations": "Ulzburger Landstraße 315 25451 Quickborn",
    "shop_url": "www.feuerwerkdiscounter.de",
    "shop_name": "Hoops Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[53.7198354, 9.9232767]",
    "shop_further_location_coordinates": []
  },
  {
    "": 55,
    "main_locations": "Von-Roth-Weg 17 88471 Laupheim",
    "shop_url": "www.mohlfeuerwerk.de",
    "shop_name": "Mohl Feuerwerk",
    "shop_further_locations": "30900 Wedemark\n33098 Paderborn\n54578 Eifel\n63450 Hanau\n70173 Stuttgart\n86150 Augsburg",
    "main_coordinates": "[48.2261147, 9.823038946809593]",
    "shop_further_location_coordinates": [[52.5394632, 9.7510923], [51.7177044, 8.752653], [50.3097609, 6.7293316], [50.132881, 8.9169797], [48.7784485, 9.1800132], [48.3668041, 10.8986971]]
  },
  {
    "": 56,
    "main_locations": "Carl-Zeiss Strasse 15 73230 Kirchheim unter Teck",
    "shop_url": "www.pyrounion.de",
    "shop_name": "Pyrounion",
    "shop_further_locations": "",
    "main_coordinates": "[48.638258699999994, 9.465248949356923]",
    "shop_further_location_coordinates": []
  },
  {
    "": 57,
    "main_locations": "Leistikostraße 22 15732 Eichwalde",
    "shop_url": "www.roehr-feuerwerk-shop.de",
    "shop_name": "Röhr Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[52.372410,13.613030]",
    "shop_further_location_coordinates": []
  },
  {
    "": 58,
    "main_locations": "Valentin-Thau-Str. 18 99955 Herbsleben",
    "shop_url": "www.darkstar-fireworks.gambiocloud.com",
    "shop_name": "Darkstar Fireworks",
    "shop_further_locations": "",
    "main_coordinates": "[51.1167116, 10.8273028]",
    "shop_further_location_coordinates": []
  },
  {
    "": 59,
    "main_locations": "Buschkamp 50 30853 Langenhagen",
    "shop_url": "www.shopalt.funkenzauber-feuerwerke.de",
    "shop_name": "Funkenzauber",
    "shop_further_locations": "Engelbosteler Damm 120 30167 Hannover",
    "main_coordinates": "[52.4514083, 9.7387923]",
    "shop_further_location_coordinates": [[52.3920312, 9.7201954]]
  },
  {
    "": 60,
    "main_locations": "Auf dem Bös 13 24601 Wankendorf",
    "shop_url": "www.pyroskyline.de",
    "shop_name": "Pyroskyline",
    "shop_further_locations": "",
    "main_coordinates": "[54.1057428, 10.208758]",
    "shop_further_location_coordinates": []
  },
  {
    "": 61,
    "main_locations": "Rödgener Dorfstraße 28 06766 Bitterfeld-Wolfen OT Rödgen",
    "shop_url": "www.zuend-idee-pyroshop.de",
    "shop_name": "Zündidee",
    "shop_further_locations": "",
    "main_coordinates": "[51.639980,12.185270]",
    "shop_further_location_coordinates": []
  },
  {
    "": 62,
    "main_locations": "Towerstraße 21, 25551 Hohenlockstedt",
    "shop_url": "www.diamondfeuerwerk.shop",
    "shop_name": "Diamond Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[53.993770, 9.565930]",
    "shop_further_location_coordinates": []
  },
  {
    "": 63,
    "main_locations": "Th-Neubauer-Str.45 06130 Halle/Saale",
    "shop_url": "www.pyrorob-feuerwerke.shop",
    "shop_name": "Pyrorob",
    "shop_further_locations": "",
    "main_coordinates": "[51.450690,11.977010]",
    "shop_further_location_coordinates": []
  },
  {
    "": 64,
    "main_locations": "Haan 26 51588 Nümbrecht",
    "shop_url": "www.shop.pyroservice-shop.de",
    "shop_name": "Pyroservice",
    "shop_further_locations": "Mispelweg 15 50767 Köln\nBrauweilerstraße 16 50859 Köln",
    "main_coordinates": "[50.8754986, 7.566101]",
    "shop_further_location_coordinates": [[51.0095551, 6.904896727000368], [50.945045300000004, 6.832822252737114]]
  },
  {
    "": 65,
    "main_locations": "Steinland 1 49688 Lastrup",
    "shop_url": "www.lagerfeuerwerksverkaufonline.de",
    "shop_name": "Feuerwerks Lager Verkauf",
    "shop_further_locations": "",
    "main_coordinates": "[52.7956452, 7.8583974]",
    "shop_further_location_coordinates": []
  },
  {
    "": 66,
    "main_locations": "Naunhofer Landstraße 46a 04288 Leipzig",
    "shop_url": "www.profipyrotechnik.de",
    "shop_name": "Pyrodrachen",
    "shop_further_locations": "",
    "main_coordinates": "[51.283923, 12.4812336]",
    "shop_further_location_coordinates": []
  },
  {
    "": 67,
    "main_locations": "Schachtstraße 1 08248 Klingenthal",
    "shop_url": "www.shop.feuerwerk-auerbach.de",
    "shop_name": "Feuerwerke Scharff",
    "shop_further_locations": "",
    "main_coordinates": "[50.38889275, 12.443264469315618]",
    "shop_further_location_coordinates": []
  },
  {
    "": 68,
    "main_locations": "Schindergraben 3a 01796 Dohma",
    "shop_url": "www.feuerwerke-kuerbs.de",
    "shop_name": "Kürbs Feuerwerke",
    "shop_further_locations": "",
    "main_coordinates": "[50.924218550000006, 13.958055596847501]",
    "shop_further_location_coordinates": []
  },
  {
    "": 69,
    "main_locations": "Scarlinoweg 18 97447 Gerolzhofen",
    "shop_url": "www.pyrotechnix.de",
    "shop_name": "Pyrotechnix",
    "shop_further_locations": "Dingolshäuser-Straße 24 97447 Gerolzhofen\nFährbrück 2 97262 Hausen\nHauptstraße 22 35425 Wettenberg-Krofdorf",
    "main_coordinates": "[49.8947376, 10.3409962]",
    "shop_further_location_coordinates": [[49.9000097, 10.3606619], [49.9182446, 10.0442237]]
  },
  {
    "": 70,
    "main_locations": "Graumannsstieg 3a 22885 Barsbüttel",
    "shop_url": "www.shop.pto-feuerwerk.de",
    "shop_name": "PTO Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[53.569866149999996, 10.164239631075613]",
    "shop_further_location_coordinates": []
  },
  {
    "": 71,
    "main_locations": "Asternweg 10 38640 Goslar",
    "shop_url": "www.pyrostube-goslar.de",
    "shop_name": "Pyrostube Goslar",
    "shop_further_locations": "",
    "main_coordinates": "[51.91569145, 10.429776825390736]",
    "shop_further_location_coordinates": []
  },
  {
    "": 72,
    "main_locations": "Trittauer Straße 16a 22952 Lütjensee",
    "shop_url": "www.nightlight-pyrotechnik.de",
    "shop_name": "Nightlight Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[53.6385259, 10.3718139]",
    "shop_further_location_coordinates": []
  },
  {
    "": 73,
    "main_locations": "Heidehöhe 19 38126 Braunschweig",
    "shop_url": "www.loewenschweif-pyrotechnik.de",
    "shop_name": "Löwenschweif Pyrotechnik",
    "shop_further_locations": "Stettinstraße 3a 38124 Braunschweig",
    "main_coordinates": "[52.23645555, 10.563073425833334]",
    "shop_further_location_coordinates": [[52.226633750000005, 10.53816391326838]]
  },
  {
    "": 74,
    "main_locations": "Bitterfelder Chaussee 13 06774 Muldestausee",
    "shop_url": "www.firemaster-feuerwerk-shop.de",
    "shop_name": "Firemaster Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[51.6177934, 12.4820238]",
    "shop_further_location_coordinates": []
  },
  {
    "": 75,
    "main_locations": "Gildemeisterstraße 90 33689 Bielefeld",
    "shop_url": "www.dragon-fireworks-bielefeld.de",
    "shop_name": "Dragon Fireworks",
    "shop_further_locations": "",
    "main_coordinates": "[51.928386950000004, 8.592085410330743]",
    "shop_further_location_coordinates": []
  },
  {
    "": 76,
    "main_locations": "Außerfeld 4 94360 Mitterfels",
    "shop_url": "www.shop.pyrotechnik-moeller.de",
    "shop_name": "Pyrotechnik Möller",
    "shop_further_locations": "",
    "main_coordinates": "[48.9805714, 12.6788263]",
    "shop_further_location_coordinates": []
  },
  {
    "": 77,
    "main_locations": "Strehlenerstraße 6 21382 Brietlingen",
    "shop_url": "www.lgfireworks.de",
    "shop_name": "LG Fireworks",
    "shop_further_locations": "",
    "main_coordinates": "[53.3144761, 10.4370973]",
    "shop_further_location_coordinates": []
  },
  {
    "": 78,
    "main_locations": "Ringstr. 8 01689 Niederau",
    "shop_url": "www.feuerwerksverkauf-meissen.de",
    "shop_name": "Light Dreams Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[51.1959875, 13.502972477620784]",
    "shop_further_location_coordinates": []
  },
  {
    "": 79,
    "main_locations": "Sewanstr  176 10319 Berlin",
    "shop_url": "www.silvesterwelt-berlin.de",
    "shop_name": "Silvester Welt Berlin",
    "shop_further_locations": "",
    "main_coordinates": "[52.4960753, 13.5102556]",
    "shop_further_location_coordinates": []
  },
  {
    "": 80,
    "main_locations": "Ringstraße 27 64297 Darmstadt",
    "shop_url": "www.flyfire.shop",
    "shop_name": "Fly Fire",
    "shop_further_locations": "Kirchstraße 51 64319 Pfungstadt",
    "main_coordinates": "[49.813926550000005, 8.648790878027619]",
    "shop_further_location_coordinates": [[49.80283945, 8.602611814970718]]
  },
  {
    "": 81,
    "main_locations": "Dobelweg 15 85567 Grafing",
    "shop_url": "www.büttner-feuerwerke.de",
    "shop_name": "Büttner Feuerwerke",
    "shop_further_locations": "",
    "main_coordinates": "[48.039487050000005, 11.96667584764398]",
    "shop_further_location_coordinates": []
  },
  {
    "": 82,
    "main_locations": "Daimlerstraße 50 73479 Ellwangen",
    "shop_url": "www.feuerwerksverkauf-jorima.de",
    "shop_name": "Jorima",
    "shop_further_locations": "",
    "main_coordinates": "[48.97388015, 10.136178508373092]",
    "shop_further_location_coordinates": []
  },
  {
    "": 83,
    "main_locations": "Georgi-Dobrowolski Str.1 15517 Fürstenwalde",
    "shop_url": "www.pyroverkauf24.de",
    "shop_name": "Pyroverkauf 24",
    "shop_further_locations": "",
    "main_coordinates": "[52.3723489, 14.0753039]",
    "shop_further_location_coordinates": []
  },
  {
    "": 84,
    "main_locations": "Maxim-Gorki-Str. 11 15831 Mahlow",
    "shop_url": "www.pyroshop-himmels-zauber.de",
    "shop_name": "Himmels-Zauber",
    "shop_further_locations": "",
    "main_coordinates": "[52.3587651, 13.411897384292566]",
    "shop_further_location_coordinates": []
  },
  {
    "": 85,
    "main_locations": "Mönchenstr. 7 14913 Jüterbog",
    "shop_url": "www.pyrohandel-jüterbog.de",
    "shop_name": "Pyrohandel Jüterbog",
    "shop_further_locations": "",
    "main_coordinates": "[51.9909812, 13.076305]",
    "shop_further_location_coordinates": []
  },
  {
    "": 86,
    "main_locations": "Heckelberger Str. 4 16259 Heckelberg-Brunow",
    "shop_url": "www.feuerwerkbude.de",
    "shop_name": "Feuerwerksbude",
    "shop_further_locations": "15344 Strausberg\n16359 Biesenthal\n16321 Bernau\n13088 Berlin\n16348 Klosterfelde\n16225 Eberswalde",
    "main_coordinates": "[52.730420699999996, 13.86904516118425]",
    "shop_further_location_coordinates": [[52.5814009, 13.8833952], [52.7664978, 13.6289589], [52.6787254, 13.5881114], [52.5170365, 13.3888599], [52.7965937, 13.4801974], [52.8350807, 13.7996542]]
  },
  {
    "": 87,
    "main_locations": "Schulstraße 50226 Frechen",
    "shop_url": "www.rheinland-pyrotechnik-shop.de",
    "shop_name": "Rheinland Pyrotechnik",
    "shop_further_locations": "Marktstr. 8 50968 Köln-Raderberg",
    "main_coordinates": "[50.92656, 6.807864]",
    "shop_further_location_coordinates": [[50.9113174, 6.960736165152813]]
  },
  {
    "": 88,
    "main_locations": "Karl-Marx-Allee 20 07747 Jena",
    "shop_url": "www.feuerwerkshop-jena.de",
    "shop_name": "Droschler Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[50.88400385, 11.607313726461712]",
    "shop_further_location_coordinates": []
  },
  {
    "": 89,
    "main_locations": "Im unteren Ried 18/1 75382 Althengstett",
    "shop_url": "www.pyrotechnik-faix-abele.de",
    "shop_name": "Feuerwerke Faix und Abele",
    "shop_further_locations": "",
    "main_coordinates": "[48.72597615, 8.783964834377343]",
    "shop_further_location_coordinates": []
  },
  {
    "": 90,
    "main_locations": "Rosenstraße 12 91560 Heilsbronn",
    "shop_url": "www.feuerwerk-pyrotechnik.com",
    "shop_name": "JK Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[49.341889949999995, 10.800203840156545]",
    "shop_further_location_coordinates": []
  },
  {
    "": 91,
    "main_locations": "Matterhornstr. 21a 81825 München",
    "shop_url": "www.pyroflowers.de",
    "shop_name": "Pyroflowers",
    "shop_further_locations": "",
    "main_coordinates": "[48.113221, 11.6573577]",
    "shop_further_location_coordinates": []
  },
  {
    "": 92,
    "main_locations": "Scheidbergstraße 9 97514 Unterschleichach",
    "shop_url": "www.sascha-vay.de/silvester",
    "shop_name": "Just for Fun",
    "shop_further_locations": "",
    "main_coordinates": "[49.9437145, 10.606871629614723]",
    "shop_further_location_coordinates": []
  },
  {
    "": 93,
    "main_locations": "Ernst-Thälmann-Str. 9 99765 Uthleben",
    "shop_url": "www.feuerwerksfreak.de",
    "shop_name": "Feuerwerksfreak",
    "shop_further_locations": "",
    "main_coordinates": "[51.4459703, 10.844337]",
    "shop_further_location_coordinates": []
  },
  {
    "": 94,
    "main_locations": "Alter Bahnhofsweg 13-15 35764 Fleisbach",
    "shop_url": "www.feuerwerk-mit-sinn.de",
    "shop_name": "Feuerwerk mit Sinn",
    "shop_further_locations": "",
    "main_coordinates": "[50.6469355, 8.3162686]",
    "shop_further_location_coordinates": []
  },
  {
    "": 95,
    "main_locations": "Borlachstraße 35 06628 Naumburg",
    "shop_url": "www.pyro-schob.de",
    "shop_name": "Pyro-Schob",
    "shop_further_locations": "",
    "main_coordinates": "[51.1309757, 11.7170555]",
    "shop_further_location_coordinates": []
  },
  {
    "": 96,
    "main_locations": "Stern Buchholz 16 19061 Schwerin",
    "shop_url": "www.shop.feuerwerke-schwerin.de",
    "shop_name": "Feuerwerke Schwerin",
    "shop_further_locations": "",
    "main_coordinates": "[53.5619804, 11.4483722]",
    "shop_further_location_coordinates": []
  },
  {
    "": 97,
    "main_locations": "Lindenstraße 52 B 25524 Itzehoe",
    "shop_url": "www.danze-feuerwerk.de",
    "shop_name": "Danze Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[53.9314973, 9.5086683]",
    "shop_further_location_coordinates": []
  },
  {
    "": 98,
    "main_locations": "Ermlitzer Strasse 42 04435 Schkeuditz",
    "shop_url": "www.yandra-fire.com",
    "shop_name": "Yang Dragon Fireworks",
    "shop_further_locations": "",
    "main_coordinates": "[51.390832700000004, 12.1890825]",
    "shop_further_location_coordinates": []
  },
  {
    "": 99,
    "main_locations": "Höslerstraße 2 86660 Tapfheim",
    "shop_url": "www.funkelfun.de",
    "shop_name": "Funkelfun",
    "shop_further_locations": "Medlinger Straße 52 89423 Gundelfingen\nEichfeldstraße 42 94496 Ortenburg\nDerchinger Straße 110 86165 Augsburg\nMarktstraße 35 74579 Unterdeufstetten\n85283 Wolnzach\n93345 Hausen\n89073 Ulm\n87719 Mindelheim\n73527 Schwäbisch Gmünd",
    "main_coordinates": "[48.6792864, 10.6990029]",
    "shop_further_location_coordinates": [[48.556799049999995, 10.358103070979606], [48.5408102, 13.2182878], [48.38724225, 10.929513555457884], [49.0538277, 10.2294463], [48.605186, 11.6233994], [48.8570916, 12.0073296], [48.3974003, 9.9934336], [48.0464164, 10.4882504], [48.7999036, 9.7977584]]
  },
  {
    "": 100,
    "main_locations": "Wilhelm-Schierenberg-Str. 2 33154 Salzkotten",
    "shop_url": "www.pyroinvasion.de",
    "shop_name": "Pyro Invasion",
    "shop_further_locations": "",
    "main_coordinates": "[51.67146525, 8.579315350000002]",
    "shop_further_location_coordinates": []
  },
  {
    "": 101,
    "main_locations": "Carl-Benz-Str. 6 77731 Willstätt",
    "shop_url": "www.sav-pyrotechnik.de",
    "shop_name": "SAV Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[48.5392233, 7.906154934149519]",
    "shop_further_location_coordinates": []
  },
  {
    "": 102,
    "main_locations": "Aischbachstraße 4 74343 Sachsenheim-Hohenhaslach",
    "shop_url": "www.pyrotechnik-brunner.de",
    "shop_name": "Pyrotechnik Brunner",
    "shop_further_locations": "",
    "main_coordinates": "[48.9971996, 9.019086599999994]",
    "shop_further_location_coordinates": []
  },
  {
    "": 103,
    "main_locations": "Britzer Str. 15 12439 Berlin",
    "shop_url": "www.pickup-pyro.de",
    "shop_name": "Pickup Pyro",
    "shop_further_locations": "Grußdorfstraße 15 13507 Berlin",
    "main_coordinates": "[52.4537225, 13.518488]",
    "shop_further_location_coordinates": [[52.5886196, 13.2861665]]
  },
  {
    "": 104,
    "main_locations": "Industriestraße 2a 22869 Schenefeld",
    "shop_url": "www.feuerwerks-garage.de",
    "shop_name": "Feuerwerks Garage",
    "shop_further_locations": "Industriestraße 2a 22869 Schenefeld bei Hamburg",
    "main_coordinates": "[53.600512, 9.8381444]",
    "shop_further_location_coordinates":[]
  },
  {
    "": 105,
    "main_locations": "Auhagenstraße 14 31863 Coppenbrügge",
    "shop_url": "www.lemke-pyrohandel.de",
    "shop_name": "Lemke Pyrohandel",
    "shop_further_locations": "An der Schachtebeeke 12 31863 Coppenbrügge",
    "main_coordinates": "[52.1109327, 9.5656965]",
    "shop_further_location_coordinates": [[52.1136238, 9.5603909]]
  },
  {
    "": 106,
    "main_locations": "Scharreler Weg 100 30900 Wedemark",
    "shop_url": "www.shop.rohr-feuerwerke.de",
    "shop_name": "Rohr Feuerwerke",
    "shop_further_locations": "",
    "main_coordinates": "[52.5290547, 9.6483671]",
    "shop_further_location_coordinates": []
  },
  {
    "": 107,
    "main_locations": "Kretscham 17 09465 Sehmatal-Neudorf",
    "shop_url": "www.fffeuertraeume.de",
    "shop_name": "FF Feuerträume",
    "shop_further_locations": "",
    "main_coordinates": "[50.4680824, 12.9873478]",
    "shop_further_location_coordinates": []
  },
  {
    "": 108,
    "main_locations": "Jakob-Wassermann-Str. 7 90763 Fürth",
    "shop_url": "www.shop.sternentanz-feuerwerk.de",
    "shop_name": "Sternentanz Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[49.455971700000006, 10.991372367534733]",
    "shop_further_location_coordinates": []
  },
  {
    "": 109,
    "main_locations": "Am Rödelberg 2 92318 Neumarkt",
    "shop_url": "www.koller-feuerwerk.de",
    "shop_name": "Koller Feuerwerk",
    "shop_further_locations": "Neumarkter Straße 21 92283 Lauterhofen",
    "main_coordinates": "[49.31106835, 11.511123520041112]",
    "shop_further_location_coordinates": [[49.366177699999994, 11.602556695697832]]
  },
  {
    "": 110,
    "main_locations": "Hans-Sachs-Str. 5 75172 Pforzheim",
    "shop_url": "www.pulverfass-pyro.de",
    "shop_name": "Pulverfass Pyro",
    "shop_further_locations": "",
    "main_coordinates": "[48.8909052, 8.6791357]",
    "shop_further_location_coordinates": []
  },
  {
    "": 111,
    "main_locations": "Westendstraße 19 86368 Gersthofen",
    "shop_url": "www.Feuerwerk-sauer.de",
    "shop_name": "Sauer",
    "shop_further_locations": "",
    "main_coordinates": "[48.4252083, 10.857168]",
    "shop_further_location_coordinates": []
  },
  {
    "": 112,
    "main_locations": "Heide 1 74389 Cleebronn",
    "shop_url": "www.zink-feuerwerk.com",
    "shop_name": "Zink Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[49.03856999196, 9.02756000358]",
    "shop_further_location_coordinates": []
  },
  {
    "": 113,
    "main_locations": "Grünauer Fenn 21 14712 Rathenow",
    "shop_url": "www.feuerwerk.soundspower.de",
    "shop_name": "Soundspower",
    "shop_further_locations": "",
    "main_coordinates": "[52.57546505, 12.340584376041665]",
    "shop_further_location_coordinates": []
  },
  {
    "": 114,
    "main_locations": "Rentwertshäuser Str. 5 98631 Grabfeld OT Behrungen",
    "shop_url": "www.pyrotechnik-schad.de",
    "shop_name": "Schad Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[50.41325001531,10.414719968296\n]",
    "shop_further_location_coordinates": []
  },
  {
    "": 115,
    "main_locations": "Einsiedler Straße 18 09235 Burkhardtsdorf",
    "shop_url": "www.colorfulpyro.de",
    "shop_name": "Colorful Pyro",
    "shop_further_locations": "",
    "main_coordinates": "[50.75276985, 12.952279749999999]",
    "shop_further_location_coordinates": []
  },
  {
    "": 116,
    "main_locations": "Bahnhofstrasse 05 12555 Berlin",
    "shop_url": "www.pyrothron.com",
    "shop_name": "Pyro Thron",
    "shop_further_locations": "",
    "main_coordinates": "[52.4509053, 13.5721464]",
    "shop_further_location_coordinates": []
  },
  {
    "": 117,
    "main_locations": "Birnenweg 5 73457 Essingen",
    "shop_url": "www.feuerwerk-essingen.de",
    "shop_name": "Feuerwerk Essingen",
    "shop_further_locations": "",
    "main_coordinates": "[48.810620099999994, 10.03638650226214]",
    "shop_further_location_coordinates": []
  },
  {
    "": 118,
    "main_locations": "Poppitzer Str.27 01589 Riesa",
    "shop_url": "www.shop.feuerwerk-sachsen.com",
    "shop_name": "Pyro-Maniac",
    "shop_further_locations": "",
    "main_coordinates": "[51.29906535, 13.31653904239315]",
    "shop_further_location_coordinates": []
  },
  {
    "": 119,
    "main_locations": "Arbachtalstr. 4 72800 Eningen",
    "shop_url": "www.kaupp-feuerwerk.de",
    "shop_name": "Kaupp Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[48.4777876, 9.2502351]",
    "shop_further_location_coordinates": []
  },
  {
    "": 120,
    "main_locations": "Wiesenstraße 9 09392 Auerbach",
    "shop_url": "www.pyrodreams.org",
    "shop_name": "Pyrodreams",
    "shop_further_locations": "",
    "main_coordinates": "[50.6817255, 12.919629240810593]",
    "shop_further_location_coordinates": []
  },
  {
    "": 121,
    "main_locations": "Große Falterstr. 32/2 70597 Stuttgart",
    "shop_url": "www.feuerwerk-stuttgart.de",
    "shop_name": "Feuerwerk Stuttgart",
    "shop_further_locations": "",
    "main_coordinates": "[48.74456035, 9.172369100000001]",
    "shop_further_location_coordinates": []
  },
  {
    "": 122,
    "main_locations": "Hirschfelder Straße 4 04451 Borsdorf",
    "shop_url": "www.feuerwerke-daniel-schlinker.de",
    "shop_name": "Feuerwerke Daniel Schlinker",
    "shop_further_locations": "",
    "main_coordinates": "[51.3341225, 12.54752941765609]",
    "shop_further_location_coordinates": []
  },
  {
    "": 123,
    "main_locations": "Schumannstraße 10 09120 Chemnitz",
    "shop_url": "www.pyro24.com",
    "shop_name": "Pyro 24",
    "shop_further_locations": "",
    "main_coordinates": "[50.81390505, 12.903985703058016]",
    "shop_further_location_coordinates": []
  },
  {
    "": 124,
    "main_locations": "Zum Bleichweiher 3 91183 Abenberg",
    "shop_url": "www.pyranjas-feuerwerke.de",
    "shop_name": "Pyranjas",
    "shop_further_locations": "",
    "main_coordinates": "[49.2467106, 10.95790615]",
    "shop_further_location_coordinates": []
  },
  {
    "": 125,
    "main_locations": "Holzmarkt 8 02625 Bautzen",
    "shop_url": "www.feuerwerke-hensel.de",
    "shop_name": "Feuerwerk Hensel",
    "shop_further_locations": "",
    "main_coordinates": "[51.1812583, 14.4340719]",
    "shop_further_location_coordinates": []
  },
  {
    "": 126,
    "main_locations": "Otto-Resch-Ring 23 09355 Gersdorf",
    "shop_url": "www.derknallbaer.de",
    "shop_name": "Knallbär",
    "shop_further_locations": "",
    "main_coordinates": "[50.752955400000005, 12.72019115]",
    "shop_further_location_coordinates": []
  },
  {
    "": 127,
    "main_locations": "Birkenauer Talstraße 4/4 69469 Weinheim",
    "shop_url": "www.aw-pyrotechnik.de",
    "shop_name": "AW Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[49.5540532, 8.6760127]",
    "shop_further_location_coordinates": []
  },
  {
    "": 128,
    "main_locations": "Parkstr. 1 65232 Taunusstein-Neuhof",
    "shop_url": "www.booster.de",
    "shop_name": "Booster",
    "shop_further_locations": "",
    "main_coordinates": "[50.170084900000006, 8.213625395493366]",
    "shop_further_location_coordinates": []
  },
  {
    "": 129,
    "main_locations": "Am Hausplatz 22 57258 Freudenberg",
    "shop_url": "www.fischer-pyrotechnik.de",
    "shop_name": "Fischer Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[50.88883705000001, 7.875357457910481]",
    "shop_further_location_coordinates": []
  },
  {
    "": 130,
    "main_locations": "Auf'm Born 4 57462 Olpe",
    "shop_url": "www.unicorn-pyrotechnik.com",
    "shop_name": "Unicorn Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[51.0676942, 7.885810426347826]",
    "shop_further_location_coordinates": []
  },
  {
    "": 131,
    "main_locations": "Bayerwaldstraße 4 81737 München",
    "shop_url": "www.feuerwerk-perlach.de",
    "shop_name": "Feuerwerk Perlach",
    "shop_further_locations": "",
    "main_coordinates": "[48.09170565, 11.63032115]",
    "shop_further_location_coordinates": []
  },
  {
    "": 132,
    "main_locations": "An der Halberstädter Chaussee 19 39116 Magdeburg",
    "shop_url": "www.feuerwerk-magdeburg-shop.de",
    "shop_name": "Hegro",
    "shop_further_locations": "",
    "main_coordinates": "[52.0959288, 11.5746033]",
    "shop_further_location_coordinates": []
  },
  {
    "": 133,
    "main_locations": "82008 Unterhaching Hauptstraße 49a",
    "shop_url": "www.himmelsschreiber-feuerwerk.de",
    "shop_name": "Himmelsschreiber",
    "shop_further_locations": "",
    "main_coordinates": "[48.0603628, 11.6187382]",
    "shop_further_location_coordinates": []
  },
  {
    "": 134,
    "main_locations": "Hermann-Schwer-Straße  8 78098  Triberg",
    "shop_url": "www.schwarzwald-pyro-power.de",
    "shop_name": "Schwarzwald Pyro Power",
    "shop_further_locations": "Untertalstraße 21 78098 Schonach",
    "main_coordinates": "[48.1309214, 8.236303154287189]",
    "shop_further_location_coordinates": [[48.13534785, 8.214381577872675]]
  },
  {
    "": 135,
    "main_locations": "Römerstr. 45 78628 Rottweil",
    "shop_url": "www.my-feuerwerk.de",
    "shop_name": "my feuerwerk",
    "shop_further_locations": "Daimlerstraße 52 72147 Nehren\nNeckarstr 47/1 78727 Oberndorf a.N.",
    "main_coordinates": "[48.1551462, 8.6429189]",
    "shop_further_location_coordinates": [[48.4274458, 9.072436304769525], [48.303705050000005, 8.575026099999999]]
  },
  {
    "": 136,
    "main_locations": "Schillerstraße 27 67122 Altrip",
    "shop_url": "www.hofacker-feuerwerk.de",
    "shop_name": "Hofacker Feuerwerk",
    "shop_further_locations": "Goethestraße 29a 67122 Altrip",
    "main_coordinates": "[49.4340215, 8.49968835]",
    "shop_further_location_coordinates": [[49.4293539, 8.4958089]]
  },
  {
    "": 137,
    "main_locations": "Saarstraße 27 76887 Bad Bergzabern",
    "shop_url": "www.pyro-concept.de",
    "shop_name": "Pyroconcept Feuerwerke",
    "shop_further_locations": "Kurfürstenstraße 5 76887 Bad Bergzabern",
    "main_coordinates": "[49.1035348, 7.994657]",
    "shop_further_location_coordinates": [[49.1070673, 8.010469]]
  },
  {
    "": 138,
    "main_locations": "Friedensstr. 33 04849 Bad Düben",
    "shop_url": "www.duebener-feuerzauber.de",
    "shop_name": "Dübner Feuerzauber",
    "shop_further_locations": "",
    "main_coordinates": "[51.5893991, 12.585282491014896]",
    "shop_further_location_coordinates": []
  },
  {
    "": 139,
    "main_locations": "Sonnenstraße 7 56587 Straßenhaus",
    "shop_url": "www.fire-effect.de",
    "shop_name": "fire-effect",
    "shop_further_locations": "",
    "main_coordinates": "[50.549394, 7.511223]",
    "shop_further_location_coordinates": []
  },
  {
    "": 140,
    "main_locations": "Lindichstraße 20 72379 Hechingen",
    "shop_url": "www.pulverfischer.de",
    "shop_name": "Pulverfischer",
    "shop_further_locations": "",
    "main_coordinates": "[48.35360005, 8.940246199595045]",
    "shop_further_location_coordinates": []
  },
  {
    "": 141,
    "main_locations": "Kirchberger Straße 46 b 08107 Kirchberg OT Cunersdorf",
    "shop_url": "www.rocket-team.de",
    "shop_name": "Rocket Team",
    "shop_further_locations": "",
    "main_coordinates": "[50.643010093199,12.515692027526]",
    "shop_further_location_coordinates": []
  },
  {
    "": 142,
    "main_locations": "Ellerneck 30 22045 Hamburg",
    "shop_url": "www.krause-feuerwerke.de",
    "shop_name": "Krause Feuerwerke",
    "shop_further_locations": "",
    "main_coordinates": "[53.5899972, 10.138894513919762]",
    "shop_further_location_coordinates": []
  },
  {
    "": 143,
    "main_locations": "Heino-Marx-Weg 67 21077 Hamburg",
    "shop_url": "www.graetzer-einzelhandel.de",
    "shop_name": "Feuerwerk Harburg",
    "shop_further_locations": "Freudenthalweg 33 21077 Hamburg",
    "main_coordinates": "[53.4434328, 9.96509]",
    "shop_further_location_coordinates": [[53.4400778, 9.9836618]]
  },
  {
    "": 144,
    "main_locations": "Karl-Ladenburg Str. 38 68163 Mannheim",
    "shop_url": "www.pyrotechnik-im-quadrat.de",
    "shop_name": "Pyrotechnik im Quadrat",
    "shop_further_locations": "",
    "main_coordinates": "[49.4812652, 8.5079261]",
    "shop_further_location_coordinates": []
  },
  {
    "": 145,
    "main_locations": "Katharinenstraße 2 Eisenach 99817",
    "shop_url": "www.fireworks.de",
    "shop_name": "Firestorm Pyro",
    "shop_further_locations": "",
    "main_coordinates": "[50.9766372, 10.31353464552388]",
    "shop_further_location_coordinates": []
  },
  {
    "": 146,
    "main_locations": "Ostring 12a 45701 Herten",
    "shop_url": "www.westfaelisches-feuerwerk.de/index.htm",
    "shop_name": "Westfälisches Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[51.6059934, 7.0989777]",
    "shop_further_location_coordinates": []
  },
  {
    "": 147,
    "main_locations": "Am Bulsmoor 5 29313 Hambühren",
    "shop_url": "www.feuerwerk-rausch.de",
    "shop_name": "Feuerwerk Rausch",
    "shop_further_locations": "Am Strandbad 29313 Hambühren",
    "main_coordinates": "[52.6246579, 9.9607117]",
    "shop_further_location_coordinates": [[52.6359643, 9.9293907]]
  },
  {
    "": 148,
    "main_locations": "Im Erlet 22 90518 Altdorf",
    "shop_url": "www.freier-pyro.de",
    "shop_name": "Freier Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[49.404278, 11.351282394669502]",
    "shop_further_location_coordinates": []
  },
  {
    "": 149,
    "main_locations": "Werner Hellweg 226 44894 Bochum",
    "shop_url": "www.fire-fantasies.de",
    "shop_name": "Fire Fantasies",
    "shop_further_locations": "",
    "main_coordinates": "[51.479859399999995, 7.283567428188358]",
    "shop_further_location_coordinates": []
  },
  {
    "": 150,
    "main_locations": "Borsigstraße 7 38644 Goslar",
    "shop_url": "www.schneider-pyrotechnik.de",
    "shop_name": "Schneider Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[51.9559889, 10.3926759]",
    "shop_further_location_coordinates": []
  },
  {
    "": 151,
    "main_locations": "Rudolstädter Straße 110 99099 Erfurt",
    "shop_url": "www.feuerwerke-lang.de",
    "shop_name": "Feuerwerke Lang",
    "shop_further_locations": "",
    "main_coordinates": "[50.9646192, 11.0750435]",
    "shop_further_location_coordinates": []
  },
  {
    "": 152,
    "main_locations": "Bahnhofstraße 4 86514 Ustersbach bei Augsburg",
    "shop_url": "www.zottis-feuerwerk.de",
    "shop_name": "Zottis Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[48.324839998188,10.652669961868]",
    "shop_further_location_coordinates": []
  },
  {
    "": 153,
    "main_locations": "Neuland 14a 86830 Schwabmünchen",
    "shop_url": "www.reiner-feuerwerke.de",
    "shop_name": "Reiner Feuerwerke",
    "shop_further_locations": "",
    "main_coordinates": "[48.1728283, 10.7573729]",
    "shop_further_location_coordinates": []
  },
  {
    "": 154,
    "main_locations": "Durmersheimer Straße 43 76185 Karlsruhe",
    "shop_url": "www.feuerwerk-pyromania-karlsruhe.de",
    "shop_name": "Pyromania",
    "shop_further_locations": "",
    "main_coordinates": "[49.0037375, 8.35575976716152]",
    "shop_further_location_coordinates": []
  },
  {
    "": 155,
    "main_locations": "Hauptstraße 3a 85095 Denkendorf",
    "shop_url": "www.feuerwerk-riedl.de",
    "shop_name": "Feuerwerk Riedl",
    "shop_further_locations": "",
    "main_coordinates": "[48.9291356, 11.4603688]",
    "shop_further_location_coordinates": []
  },
  {
    "": 156,
    "main_locations": "Obere Hauptstrasse 8A 09337 Bernsdorf",
    "shop_url": "www.pyroscheune.de",
    "shop_name": "Pyroscheune",
    "shop_further_locations": "",
    "main_coordinates": "[50.783927649999995, 12.668460199999998]",
    "shop_further_location_coordinates": []
  },
  {
    "": 157,
    "main_locations": "Elsässer Weg 5 77876 Kappelrodeck",
    "shop_url": "www.kistfeuerwerk.de",
    "shop_name": "Kist Feuerwerk",
    "shop_further_locations": "",
    "main_coordinates": "[48.593236649999994, 8.107586940225001]",
    "shop_further_location_coordinates": []
  },
  {
    "": 158,
    "main_locations": "Steller Heide 12 28790 Schwanewede",
    "shop_url": "www.nordic-fireworks.de",
    "shop_name": "Nordic Fireworks",
    "shop_further_locations": "",
    "main_coordinates": "[53.2264755, 8.5356329]",
    "shop_further_location_coordinates": []
  },
  {
    "": 159,
    "main_locations": "Regelsbacher Straße 31 90547 Stein",
    "shop_url": "www.eventf.de",
    "shop_name": "Event F",
    "shop_further_locations": "",
    "main_coordinates": "[49.402253200000004, 11.007927886689526]",
    "shop_further_location_coordinates": []
  },
  {
    "": 160,
    "main_locations": "Lohgerberstr. 2 84524 Neuötting",
    "shop_url": "www.galaxis-feuerwerk.de",
    "shop_name": "Galaxis",
    "shop_further_locations": "",
    "main_coordinates": "[48.2424533, 12.703203574193548]",
    "shop_further_location_coordinates": []
  },
  {
    "": 161,
    "main_locations": "Südwall 26 46397 Bocholt",
    "shop_url": "www.lesli.de",
    "shop_name": "Lesli",
    "shop_further_locations": "",
    "main_coordinates": "[51.8383006, 6.620947037277711]",
    "shop_further_location_coordinates": []
  },
  {
    "": 162,
    "main_locations": "Ulmenstraße 13 84036 Kumhausen",
    "shop_url": "www.pyro-rabbit.de",
    "shop_name": "Pyro Rabbit",
    "shop_further_locations": "Münchnerau 32 84034 Landshut",
    "main_coordinates": "[48.51077145, 12.14905130739935]",
    "shop_further_location_coordinates": [[48.5305432, 12.0872857]]
  },
  {
    "": 163,
    "main_locations": "Hanauer Landstr. 491 60386 Frankfurt am Main",
    "shop_url": "www.feuerwerksladen-rhein-main.de",
    "shop_name": "Feuerwerksladen Rhein Main",
    "shop_further_locations": "",
    "main_coordinates": "[50.12888065, 8.755977409951605]",
    "shop_further_location_coordinates": []
  },
  {
    "": 164,
    "main_locations": "St.-Josef-Straße 11e 39130 Magdeburg",
    "shop_url": "www.firestar-pyrotechnik.shop",
    "shop_name": "FireStar Pyrotechnik",
    "shop_further_locations": "",
    "main_coordinates": "[52.153301, 11.579020]",
    "shop_further_location_coordinates": []
  },
  {
    "": 165,
    "main_locations": "Am Feldgraben 506268 Querfurt ot Spielberg",
    "shop_url": "www.instagram.com/pyrokayserfeuerwerke",
    "shop_name": "Pyrokayser Feuerwerke",
    "shop_further_locations": "",
    "main_coordinates": "[51.321040, 11.585830]",
    "shop_further_location_coordinates": []
  }
];export default pickupLocationData;